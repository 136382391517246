import * as d3 from "d3";
import { geoRobinson } from "d3-geo-projection";
import { useEffect, useRef } from "react";
import {
  LegendBar,
  LegendContainer,
  LegendLabel,
  MapContainer,
  WorldMapData,
} from "../common";
import { features as worldFeatures } from "./world.json";
import { useCustomAppContext } from "contexts/CustomAppContext";

type Props = {
  width: number;
  data: WorldMapData[];
};
export const WorldMap = ({ width, data }: Props) => {
  const container = useRef();
  const { customApp } = useCustomAppContext();

  useEffect(() => {
    const height = width / 2;

    ///@ts-ignore-next-line
    container.current.replaceChildren();
    const projection = geoRobinson()
      .translate([width / 2, 25 + height / 2])
      .scale(10 + width / 6);
    const path = d3.geoPath().projection(projection);
    const paint = d3
      .scaleLinear()
      ///@ts-ignore-next-line
      .range(["#DAE0E3", customApp?.theme.colors.main || "#2a01af"])
      .domain([0, 1]);

    const svg = d3
      .select(container.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const tooltip = d3
      .select(container.current)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    svg
      .selectAll("path")
      .data(worldFeatures)
      .enter()
      .append("path")
      ///@ts-ignore-next-line
      .attr("d", path)
      .style("stroke", "#fff")
      .style("stroke-width", "1")
      .style("fill", (d) => {
        const res = data?.find((c) => c.iso_a2 === d.properties.iso_a2);
        return res ? paint(res.value) : "#DAE0E3";
      })
      .on("mouseover", function () {
        tooltip.transition().duration(200).style("opacity", 0.9);
        const bbox = this.getBBox();
        tooltip
          ///@ts-ignore-next-line
          .text(this.__data__.properties.admin)
          .style("left", bbox.x + bbox.width / 2 + 20 + "px")
          .style("top", bbox.y + 25 + "px");
      })
      .on("mouseout", function (d) {
        tooltip.transition().duration(500).style("opacity", 0);
      });
  }, [data, width]);

  return <MapContainer ref={container} />;
};
export const WorldLegend = () => {
  return (
    <LegendContainer>
      <LegendBar />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "280px",
        }}
      >
        <LegendLabel>Fewer Fans</LegendLabel>
        <LegendLabel>More Fans</LegendLabel>
      </div>
    </LegendContainer>
  );
};
