import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  useSortable,
  UseSortableArguments,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function animateLayoutChanges(
  args: Parameters<AnimateLayoutChanges>[0],
) {
  const { isSorting, wasDragging } = args;

  if (isSorting || wasDragging) {
    return defaultAnimateLayoutChanges(args);
  }

  return true;
}

export const useSortableAttributes = (
  id: string,
  args?: Omit<UseSortableArguments, "id">,
) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, ...args });

  const style = {
    opacity: isDragging ? 0 : 1,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return { setNodeRef, attributes, listeners, style };
};
