import styled from "styled-components";
import { SvgForward } from "melodies-source/Svgs/Forward";

type FontProps = {
  fontWeight?: number;
  center?: boolean;
  right?: boolean;
  color?: string;
};

export const BaseFont = styled.span<FontProps>`
  font-family: Poppins;
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  font-size: 12px;
  line-height: 18px;

  ${({ center }) => center && "text-align: center;"}
  ${({ right }) => right && "text-align: right;"}

  color: ${({ color }) => color ?? "white"};

  ${({ theme }) => theme.media.mobile} {
    font-size: 9.488px;
    line-height: 14px;
  }
`;

export const CenterFlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Font10 = styled(BaseFont)`
  font-size: 10px;
  line-height: 15px;
`;

export const Font11 = styled(BaseFont)`
  font-size: 11px;
  line-height: 16px;
`;

export const Font12 = styled(BaseFont)``;

export const Font13 = styled(BaseFont)`
  font-size: 13px;
  line-height: 20px;
`;

export const Font14 = styled(BaseFont)`
  font-size: 14px;
  line-height: 21px;

  ${({ theme }) => theme.media.mobile} {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Font15 = styled(BaseFont)`
  font-size: 15px;
  line-height: 22px;

  ${({ theme }) => theme.media.mobile} {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Font16 = styled(BaseFont)`
  font-size: 16px;
  line-height: 24px;

  ${({ theme }) => theme.media.mobile} {
    font-size: 13.39px;
    line-height: 20px;
  }
`;

export const Font17 = styled(BaseFont)`
  font-size: 17px;
  line-height: 28px;

  ${({ theme }) => theme.media.mobile} {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Font18 = styled(BaseFont)`
  font-size: 18px;
  line-height: 27px;
  ${({ theme }) => theme.media.mobile} {
    font-size: 13x;
    line-height: 19.5px;
  }
`;

export const Font20 = styled(BaseFont)`
  font-size: 20px;
  line-height: 30px;

  ${({ theme }) => theme.media.mobile} {
    font-size: 14px;
    line-height: 25px;
  }
`;

export const Font24 = styled(BaseFont)`
  font-size: 24px;
  line-height: 36px;

  ${({ theme }) => theme.media.mobile} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const Font26 = styled(BaseFont)`
  font-size: 26px;
  line-height: 39px;

  ${({ theme }) => theme.media.mobile} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const Font30 = styled(BaseFont)`
  font-size: 30px;
  line-height: 45px;

  ${({ theme }) => theme.media.mobile} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const Font38 = styled(BaseFont)`
  font-size: 38px;
  line-height: 54px;
`;

export const Font40 = styled(BaseFont)`
  font-size: 40px;
  line-height: 65px;

  ${({ theme }) => theme.media.mobile} {
    font-size: 23px;
    line-height: 54px;
  }
`;

export const AnimatedCaret = styled(SvgForward)<{
  direction: "up" | "down" | "left" | "right";
  $animDuration?: number;
  $withTransition?: boolean;
}>`
  cursor: pointer;
  transform: ${({ direction }) => caretTransform(direction)};
  transition: ${(p) =>
    p.$withTransition ?? true
      ? `transform ${p.$animDuration ?? 0.35}s linear`
      : "none"};
`;

const caretTransform = (direction: "up" | "down" | "left" | "right") => {
  if (direction === "up") {
    return "rotate(90deg) scaleX(-1)";
  }

  if (direction === "down") {
    return "rotate(90deg)";
  }

  if (direction === "left") {
    return "scaleY(-1)";
  }

  if (direction === "right") {
    return "scaleY(1)";
  }
};

export const truncateStyle: React.CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
};

export const truncateCSS = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const TruncatedText = styled.span`
  ${truncateCSS}
`;

export const LineClamp = styled.span<{ $lines?: number }>`
  ${({ $lines }) => `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @supports (-webkit-line-clamp: ${$lines ?? 1}) {
    overflow: hidden;
    white-space: initial;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${$lines ?? 1};
    -webkit-box-orient: vertical;
  }
`}
`;
