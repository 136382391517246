import styled, { css } from "styled-components";
import { Card, Button } from "Components";
import { FlexColumn, FlexRow } from "Components/Flex";
import { H2, H4 } from "melodies-source/Text";
import { LineClamp } from "Routes/styled";

type AppCardProps = {
  compact?: boolean;
  logo: JSX.Element;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
  primaryAction?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const AppCard = ({
  compact,
  children,
  logo,
  primaryButtonText,
  onPrimaryClick,
  onSecondaryClick,
  secondaryButtonText,
  primaryAction,
  ...props
}: AppCardProps) => {
  return (
    <AppCardWrapper {...props}>
      <Logo>{logo}</Logo>
      <Actions gap="12px">
        {onSecondaryClick && (
          <AppCardSecondaryButton variant="outlined" onClick={onSecondaryClick}>
            {secondaryButtonText}
          </AppCardSecondaryButton>
        )}
        {primaryAction ||
          (onPrimaryClick && (
            <AppCardPrimaryButton onClick={onPrimaryClick}>
              {primaryButtonText}
            </AppCardPrimaryButton>
          ))}
      </Actions>
      <ListContainer>{children}</ListContainer>
    </AppCardWrapper>
  );
};

const Actions = styled(FlexRow)`
  grid-area: actions;
  justify-content: flex-end;

  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column-reverse;
    justify-self: center;
    margin-top: 24px;
    max-width: 600px;
    width: 100%;
  }
`;

const ListContainer = styled.div`
  display: grid;
  row-gap: 16px;
  padding-top: 20px;
  color: #ffffff;
  grid-area: content;

  ${H2} {
    font-weight: 600;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    max-width: 600px;
    width: 100%;
    justify-self: center;
  }
  ${({ theme }) => theme.mediaQueries.desktop} {
    grid-template: auto / 1fr 1fr;
    column-gap: 44px;
    padding-top: 32px;
  }
  ${({ theme }) => theme.media.maxTablet} {
    grid-template: auto / 1fr;
    ${H2} {
      font-size: 20px;
      line-height: 28px;
      text-align: center;
    }
    & > div > ${H2} {
      margin-top: 16px;
    }
  }
`;

export const AppCardWrapper = styled(Card)`
  display: grid;
  grid-template-areas: "logo actions" "content content";
  grid-template-columns: 100px 1fr;
  grid-template-rows: 40px auto;
  border: none;
  border-radius: 20px;
  max-width: 1300px;
  margin: auto 40px;
  padding: 32px 44px 44px;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.mobile} {
    border-radius: 0;
    margin: auto 0;
    padding: 32px 20px 44px;
    grid-template-areas: "logo" "content" "actions";
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;

const Logo = styled.div`
  grid-area: logo;
  svg {
    width: auto;
    height: 32px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    place-self: center;
  }

  ${({ theme }) => theme.custom && "display: none;"}
`;

export const AppCardPrimaryButton = styled(Button)`
  border: none;
  border-radius: 20px;
  color: #1b0076;
  height: 40px;
  padding: 10px 20px;
  background-color: #ffffff;
  min-width: 160px;
  &:hover {
    background-color: #ffffff;
    color: #1b0076;
  }
`;

const AppCardSecondaryButton = styled(Button)`
  border-color: #fff;
  color: #fff;
  height: 40px;
  padding: 10px 20px;
  border-radius: 20px;
  min-width: 160px;
  &:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #1b0076;
  }
`;

export type ListItemButtonProps = {
  title: string;
  description: string;
  icon?: React.ReactNode;
  avatar?: React.ReactNode;
  day?: string;
  dayOfWeek?: string;
  month?: string;
  secondary?: boolean;
  metricLabel?: string;
  metricValue?: string | number;
} & React.HTMLAttributes<HTMLDivElement>;

export const ListItemButton = ({
  title,
  description,
  icon,
  avatar,
  day,
  dayOfWeek,
  month,
  secondary,
  metricLabel,
  metricValue,
  ...props
}: ListItemButtonProps) => {
  return (
    <ListItemButtonContainer secondary={secondary} {...props}>
      <Container>
        {avatar || (
          <ListItemButtonAvatar xCenter yCenter>
            {icon ?? (
              <>
                {dayOfWeek && <Name>{dayOfWeek}</Name>}
                {month && <Month>{month}</Month>}
                {day && <Day>{day}</Day>}
              </>
            )}
          </ListItemButtonAvatar>
        )}
        <ContentContainer>
          <LineClamp as={H4}>{title}</LineClamp>
          <LineClamp as={H4}>{description}</LineClamp>
        </ContentContainer>
        <ListItemButtonMetric>
          <H2>{metricValue.toLocaleString()}</H2>
          <H4>{metricLabel}</H4>
        </ListItemButtonMetric>
      </Container>
      <CustomBackground />
    </ListItemButtonContainer>
  );
};

export const ListItemButtonMetric = styled(FlexColumn)`
  align-items: center;
  min-width: 90px;
  ${H2}, ${H4} {
    text-align: center;
  }
  ${H4} {
    font-size: 14px;
    line-height: 18px;
  }
  ${H2} {
    line-height: 26px;
    font-weight: 700;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    ${H4} {
      font-size: 11px;
      line-height: 14px;
    }
    ${H2} {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

const ContentContainer = styled(FlexColumn)`
  flex-grow: 1;
  ${H4} {
    font-weight: 600;
    line-height: 22px;
  }
  ${H4} + ${H4} {
    font-weight: 400;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    ${H4} {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const Name = styled.span`
  font-size: 7px;
  font-weight: 600;
  line-height: 7px;
  font-family: Poppins;
  white-space: nowrap;
  text-transform: uppercase;
  margin-top: -1px;
  margin-bottom: 1px;
  color: #ffffffcc;
`;

const Day = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  font-family: Poppins;
  margin-top: 1px;
`;

const Month = styled.span`
  font-size: 10px;
  line-height: 10px;
  font-weight: 600;
  font-family: Poppins;
  white-space: nowrap;
  text-transform: uppercase;
`;

export const ListItemButtonAvatar = styled(FlexColumn)`
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 22px;
  color: #ffffff;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 32px;
    height: 32px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const CustomBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  display: none;
  background: rgba(27, 0, 118, 0.5);
  mix-blend-mode: multiply;
`;

const Container = styled(FlexRow)`
  align-items: center;
  gap: 12px;
  flex-grow: 1;
  z-index: 1;
`;

const ListItemButtonContainer = styled(FlexRow)<{ secondary?: boolean }>`
  padding: 0 16px;
  border-radius: 12px;
  color: #333333;
  cursor: pointer;
  height: 80px;
  position: relative;
  overflow: hidden;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  ${({ theme, secondary }) =>
    theme.custom &&
    !secondary &&
    css`
      color: #ffffff;
      ${CustomBackground} {
        display: block;
      }
    `}

  ${({ theme }) =>
    !theme.custom &&
    css`
      background-color: #ffffff;
    `};

  ${({ secondary, theme }) =>
    secondary &&
    css`
      background-color: rgba(0, 0, 0, 0.25);
      color: #ffffff;

      ${theme.custom &&
      css`
        ${ListItemButtonAvatar} {
          display: none;
        }
      `}
    `}

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0 10px;
    height: 57px;
  }
`;
