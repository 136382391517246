import { useCreateSetLiveEvent, useEventEntries, useTimeDiff } from "hooks";
import { useHistory, useParams } from "react-router-dom";
import { SetLiveAppNoData } from "./AppCardNoData";
import styled from "styled-components";
import { FlexColumn } from "Components/Flex";
import { SetLiveKnockout } from "assets/logos";
import { Body1, H2 } from "melodies-source/Text";
import {
  AppCard,
  AppCardPrimaryButton,
  ListItemButton,
  ListItemButtonAvatar,
} from "./AppCard";
import { DateTime } from "luxon";
import { SvgLocation } from "melodies-source/Svgs/Location";
import { BorderContainer } from "./common";
import { ReactComponent as IconMicrophone } from "assets/svg/microphone.svg";
import { SetLiveEvent } from "models/event";
import { truncateString } from "Utils";
import { formatNumber } from "Utils/format";
import { SvgDropdown } from "melodies-source/Svgs/Dropdown";
import { Dropdown } from "melodies-source/Dropdown";
import { ListItem } from "melodies-source/ListItem";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";
import { SvgCopy } from "melodies-source/Svgs/Copy";
import { useState } from "react";
import { Spinner } from "melodies-source/Spinner";
import { useIsMobile } from "melodies-source/utils";
import { CalendarDisplay } from "Components";

interface SetLiveAppCardProps extends React.HTMLAttributes<HTMLDivElement> {
  custom?: boolean;
  active?: boolean;
  shows: {
    upcoming: SetLiveEvent[];
    past: SetLiveEvent[];
  };
}

export const SetLiveAppCard = ({
  active,
  shows,
  ...props
}: SetLiveAppCardProps) => {
  const { artistId } = useParams<{ artistId: string }>();
  const { upcoming, past } = shows;
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const { createEvent, loading } = useCreateSetLiveEvent({ artistId });
  const isMobile = useIsMobile();

  if (!active) {
    return <SetLiveAppNoData {...props} />;
  }

  return (
    <StyledAppCard
      logo={<SetLiveKnockout />}
      secondaryButtonText="View All"
      onSecondaryClick={() => history.push("set-live/upcoming-shows")}
      primaryAction={
        isMobile ? (
          ""
        ) : (
          <FlexColumn>
            <AppCardPrimaryButton
              rightIcon={<SvgDropdown />}
              onClick={() => setIsOpen(!isOpen)}
            >
              Add Show
            </AppCardPrimaryButton>
            <Dropdown
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              placement="bottom"
              style={{ borderRadius: 8, marginTop: 4 }}
            >
              <ListItem
                leftIcon={!loading && <SvgAddAlt />}
                onClick={() => createEvent()}
              >
                {loading ? (
                  <Spinner color="#999" style={{ height: 28 }} />
                ) : (
                  "New Show"
                )}
              </ListItem>
              <ListItem
                leftIcon={<SvgCopy />}
                onClick={() =>
                  history.push("set-live/upcoming-shows", { duplicate: true })
                }
              >
                Duplicate Show
              </ListItem>
            </Dropdown>
          </FlexColumn>
        )
      }
    >
      <List>
        <H2>Upcoming</H2>
        {!upcoming?.length ? (
          <BorderContainer>
            <IconMicrophone />
            <Body1>No Upcoming Shows</Body1>
          </BorderContainer>
        ) : (
          upcoming.map((show) => (
            <EventCardUpcoming
              key={`upcoming-${show.id}`}
              event={show}
              onClick={() =>
                history.push(`reporting/set-live/shows/${show.id}`)
              }
            />
          ))
        )}
      </List>
      <List>
        <H2>Past</H2>
        {!past?.length ? (
          <BorderContainer>
            <IconMicrophone />
            <Body1>No Past Shows</Body1>
          </BorderContainer>
        ) : (
          past.map((show) => (
            <EventCardPast
              key={`past-${show.id}`}
              event={show}
              onClick={() =>
                history.push(`reporting/set-live/shows/${show.id}`)
              }
            />
          ))
        )}
      </List>
    </StyledAppCard>
  );
};

const StyledAppCard = styled(AppCard)`
  background: ${({ theme }) =>
    theme.custom
      ? theme.custom.pages.dashboard.cards.shows.background
      : "linear-gradient(225deg, #E71F3D 13.35%, #67000F 92.93%)"};

  ${ListItemButtonAvatar} {
    color: #ffffff;
    background-color: #e71e3d;
  }
`;

type EventCardProps = {
  event: SetLiveEvent;
  onClick: VoidFunction;
} & React.HTMLAttributes<HTMLDivElement>;

const List = styled(FlexColumn)`
  gap: 16px;
`;

const EventCardUpcoming = ({ event, onClick }: EventCardProps) => {
  const { diff: timeUntilEvent, time } = useTimeDiff({
    eventTime: event.startsAt,
  });
  let startsAt = DateTime.fromMillis(event.startsAt);
  if (event.timeZoneName) {
    startsAt = startsAt.setZone(event.timeZoneName);
  }
  const isLiveNow = startsAt < time;

  const timeUntilValue = isLiveNow ? "Live" : timeUntilEvent.value;
  const timeUntilUnit = isLiveNow ? "Now" : timeUntilEvent.unit;

  const displayAddress = truncateString(event.displayAddress || event.address);
  return (
    <ListItemButton
      avatar={<CalendarDisplay date={startsAt} />}
      day={startsAt.toFormat("dd")}
      dayOfWeek={startsAt.toFormat("ccc")}
      month={startsAt.toFormat("LLL")}
      title={event.venue}
      description={displayAddress}
      metricValue={timeUntilValue}
      metricLabel={timeUntilUnit}
      onClick={onClick}
    />
  );
};

const EventCardPast = ({ event, onClick }: EventCardProps) => {
  const { diff: timeSinceEvent } = useTimeDiff({
    eventTime: event.endedAt,
  });
  let endedAt = DateTime.fromMillis(event.endedAt);
  if (event.timeZoneName) {
    endedAt = endedAt.setZone(event.timeZoneName);
  }
  const { entriesTotals } = useEventEntries(event.id, event.version);

  const displayAddress = truncateString(event.displayAddress || event.address);
  return (
    <ListItemButton
      secondary
      onClick={onClick}
      icon={<SvgLocation width={30} height={30} />}
      title={event.venue}
      description={`${
        timeSinceEvent.value === 0 ? "Live Now" : endedAt.toFormat("LLL d")
      } • ${displayAddress}`}
      metricValue={formatNumber(entriesTotals?.all ?? 0)}
      metricLabel={(entriesTotals?.all ?? 0) !== 1 ? "Entries" : "Entry"}
    />
  );
};
